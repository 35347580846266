<template>
  <div class="flex flex-col min-h-screen">
    <main class="w-full max-w-3xl mx-auto p-2">
      <div class="shadow-lg bg-local bg-center text-md md:text-xl">
        <template v-if="loading">
          <Loading />
        </template>

        <template v-else>
          <div class="relative">
            <div v-if="isUpload" class="fixed z-10 inset-0 overflow-y-auto">
              <div
                class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
              >
                <div
                  class="fixed inset-0 transition-opacity"
                  aria-hidden="true"
                >
                  <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>

                <!-- This element is to trick the browser into centering the modal contents. -->
                <span
                  class="hidden sm:inline-block sm:align-middle sm:h-screen"
                  aria-hidden="true"
                  >&#8203;</span
                >

                <div
                  class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                  role="dialog"
                  aria-modal="true"
                  aria-labelledby="modal-headline"
                >
                  <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div class="sm:flex sm:items-start">
                      <div
                        class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left"
                      >
                        <h3
                          class="text-lg leading-6 font-medium text-gray-900"
                          id="modal-headline"
                        >
                          Upload Photo
                        </h3>
                        <hr />
                        <div class="mt-2">
                          <p class="text-sm">
                            Silakan upload foto yang menggambarkan aktivitas
                            part timer saat melakukan poin penilaian
                          </p>
                        </div>
                        <div class="text-sm p-5">
                          <p
                            class="text-left"
                            v-html="listQuestionForModal"
                          ></p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="m-4 flex flex-wrap content-start">
                    <label
                      class="w-32 flex flex-col items-center bg-white text-blue shadow-lg tracking-wide border rounded-md border-blue cursor-pointer bg-blue-500 hover:bg-blue-700 text-white"
                    >
                      <span class="my-2 text-base leading-normal">Pilih</span>
                      <input
                        type="file"
                        class="hidden"
                        id="file"
                        ref="file"
                        @change="onFileChange()"
                        accept="image/jpeg, image/png"
                      />
                    </label>
                    <span class="flex p-2 text-base font-medium">{{ preparedPhoto.name || file_names[currentQuestionId] }}</span>
                  </div>
                  <div id="preview" class="px-4">
                    <img
                      v-if="preparedPhoto.url || urls[currentQuestionId]"
                      :src="preparedPhoto.url || urls[currentQuestionId]"
                    />
                  </div>
                  <div
                    class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse"
                  >
                    <button
                      @click="uploadPhoto()"
                      type="button"
                      class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                    >
                      Simpan
                    </button>
                    <button
                      @click="popUpModal()"
                      type="button"
                      class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="p-8">
              <b>CHECKLIST AWAL PART TIMER OLEH PIMPINAN SHIFT</b>
            </div>
            <div class="text-left m-4 md:m-20 text-xs sm:text-base">
              <div>
                <div class="flex flex-wrap">
                  <div class="w-36 sm:w-72">Nama Pimpinan</div>
                  <span>: </span>
                  <span class="ml-4 sm:ml-10 font-bold"
                    >{{ participant.store_leader_name }}
                  </span>
                </div>
                <div class="flex">
                  <div class="w-36 sm:w-72">Kode & Nama Toko</div>
                  <span>: </span>
                  <span class="ml-4 sm:ml-10 font-bold">
                    {{ participant.store_code }} {{ participant.store_name }}</span
                  >
                </div>
                <div class="flex">
                  <div class="w-36 sm:w-72">Nama Area Supervisor</div>
                  <span>:</span>
                  <span class="ml-4 sm:ml-10">
                    <b> {{ participant.area_supervisor_name }}</b>
                  </span>
                </div>

                <div class="flex">
                  <div class="w-36 sm:w-72">Nama Area Manager</div>
                  <span>:</span>
                  <span class="ml-4 sm:ml-10">
                    <b>{{ participant.area_manager_name }} </b>
                  </span>
                </div>
                <div class="flex">
                  <div class="w-36 sm:w-72">Tanggal Training</div>
                  <span>:</span>
                  <span class="ml-4 sm:ml-10 font-bold">{{
                    participant.training_date
                  }}</span>
                </div>
                <div class="flex">
                  <div class="w-36 sm:w-72">Nama Calon Part Timer</div>
                  <span>:</span>
                  <span class="ml-4 sm:ml-10 font-bold">{{ full_name }}</span>
                </div>
                <div class="flex">
                  <div class="w-36 sm:w-72">Nomor Rekruter</div>
                  <span>: </span>
                  <span class="ml-4 sm:ml-10 font-bold"
                    >{{ participant.recruiter_phone_number }}
                  </span>
                </div>
              </div>
              <hr class="line-bold" />

              <div v-if="section === 'A'">
                <div class="text-base lg:text-2xl">
                  <b> A. Pra Penilaian</b>
                </div>
                <div>
                  <p>
                    Cara pengisian: Jika Pimpinan Shift sudah melakukan langkah
                    tersebut silakan klik tombol "✓" jika tidak dilakukan
                    silakan pilih tombol
                    <b>"X"</b>
                  </p>
                </div>
                <div
                  v-for="(checkList, checkListIndex) in checkListSection"
                  :key="checkListIndex"
                >
                  <div v-if="checkList.display != 'after'" class="font-bold text-xl">
                    {{ checkListIndex + 1 }} . {{ checkListIndex.name }}
                  </div>
                  <div
                    :class="checkBoldOrNot(checkListIndex)"
                    v-html="checkList.description"
                  ></div>
                  <div
                    v-for="(checkListSubSection, checkListSubSectionIndex) in checkList.checklists"
                    :key="checkListSubSectionIndex"
                  >
                    <div class="flex items-center flex-wrap sm:justify-between">
                      <div class="min-w-full lg:min-w-0">
                        {{ checkListSubSection.description }}
                      </div>
                      <div class="flex cursor-pointer">
                        <div
                          class="flex border border-green-300 p-2 pr-8 mt-4 sm:mt-0"
                        >
                          <input
                            v-model="
                              answers.id[checkListSubSection.id]
                            "
                            :id="
                              checkLabel(
                                checkListIndex,
                                checkListSubSectionIndex,
                                'benar'
                              )
                            "
                            value="1"
                            :name="checkListSubSection.id"
                            type="radio"
                            class="form-radio h-5 w-5 text-gray-600"
                          />
                          <label
                            class="ml-4 cursor-pointer"
                            :for="
                              checkLabel(
                                checkListIndex,
                                checkListSubSectionIndex,
                                'benar'
                              )
                            "
                          >
                            ✓</label
                          >
                        </div>
                        <div
                          v-if="checkListSubSection.yes_only == 0"
                          class="flex border border-pink-300 p-2 pr-8 ml-2 mt-4 sm:mt-0"
                        >
                          <input
                            v-model="
                              answers.id[checkListSubSection.id]
                            "
                            :id="
                              checkLabel(
                                checkListIndex,
                                checkListSubSectionIndex,
                                'salah'
                              )
                            "
                            value="0"
                            :name="checkListSubSection.id"
                            type="radio"
                            class="form-radio h-5 w-5 text-gray-600"
                          />
                          <label
                            class="ml-4 cursor-pointer"
                            :for="
                              checkLabel(
                                checkListIndex,
                                checkListSubSectionIndex,
                                'salah'
                              )
                            "
                          >
                            X</label
                          >
                        </div>
                      </div>
                    </div>
                    <hr class="line-bold" />
                  </div>
                </div>
                <div class="flex w-full justify-center">
                  <button
                    @click="submitPreTest()"
                    :class="`bg-blue-500 hover:bg-blue-700 text-white font-bold m-2 py-2 px-4 rounded ${loadingBtn ? 'opacity-50 cursor-wait' : ''}`"
                  >
                    Simpan
                  </button>
                </div>
              </div>
            </div>

            <div
              v-if="section === 'B'"
              class="text-left m-10 sm:m-20 text-xs sm:text-base leading-5 md:leading-7"
            >
              <div class="font-bold text-base lg:text-2xl">
                B. Penilaian Praktek di Toko
              </div>
              <div>
                <p>
                  Cara pengisian : Pimpinan Shift mengklik pilihan
                  <span>Baik,</span> <span>Cukup</span> atau
                  <span>KURANG</span> disetiap praktek <br />yang dilakukan oleh
                  Pelamar
                </p>
              </div>
              <div>Kategori Penilaian</div>
              <div>
                <div>
                  1. Nilai <span>BAIK</span> jika dilakukan sesuai SOP dan tidak
                  ada kesalahan
                </div>
                <div>
                  2. Nilai <span>CUKUP</span> jika dilakukan sesuai SOP namun
                  ada beberapa kesalahan
                </div>
                <div>
                  3. Nilai <span>KURANG</span> jika dilakukan tidak sesuai SOP
                </div>
              </div>
              <div
                v-for="(practicalList, Listindex) in practicalListSection"
                :key="Listindex"
              >
                <div class="font-bold mt-8 text-xl">
                  {{ Listindex + 1 }}.{{ practicalList.name }}
                </div>
                <div
                  v-for="(practicalListSubSection, index) in practicalList.sub_sections"
                  :key="index"
                >
                  <div>
                    <div
                      class="mt-4"
                      v-html="practicalListSubSection.description"
                    ></div>
                    <div
                      v-for="(testQuestion,
                      questionIndex) in practicalListSubSection.test_questions"
                      :key="questionIndex"
                    >
                      <div>
                        <div>{{ practicalListSubSection.name }}</div>
                        <div
                          class="ml-4 md:ml-9 text-left"
                          v-html="testQuestion.question"
                        ></div>
                        <div
                          class="flex flex-col md:flex-col lg:flex-row cursor-pointer"
                        >
                          <div
                            class="flex border border-green-300 rounded p-2 pr-8 mr-5 mt-5"
                          >
                            <input
                              v-model="practicalAnswers.id[testQuestion.id]"
                              :name="testQuestion.question"
                              type="radio"
                              value="10"
                              :id="checkLabel(index, Listindex, 'Baik')"
                              class="form-radio h-5 w-5 text-gray-600"
                            />
                            <label
                              :for="checkLabel(index, Listindex, 'Baik')"
                              class="ml-5"
                            >
                              Baik</label
                            >
                          </div>
                          <div
                            class="flex border border-pink-300 rounded p-2 pr-8 mr-5 mt-5"
                          >
                            <input
                              v-model="practicalAnswers.id[testQuestion.id]"
                              :name="testQuestion.question"
                              type="radio"
                              value="6"
                              :id="checkLabel(index, Listindex, 'Cukup')"
                              class="form-radio h-5 w-5 text-gray-600"
                            />
                            <label
                              :for="checkLabel(index, Listindex, 'Cukup')"
                              class="ml-5"
                            >
                              Cukup</label
                            >
                          </div>
                          <div
                            class="flex border border-pink-300 rounded p-2 pr-8 mr-5 mt-5"
                          >
                            <input
                              v-model="practicalAnswers.id[testQuestion.id]"
                              type="radio"
                              value="3"
                              :id="checkLabel(index, Listindex, 'Kurang')"
                              class="form-radio h-5 w-5 text-gray-600"
                            />
                            <label
                              :for="checkLabel(index, Listindex, 'Kurang')"
                              class="ml-5"
                            >
                              Kurang</label
                            >
                          </div>
                          <div class="flex mr-5 mt-5">
                            <button
                              @click="
                                popUpModal(
                                  testQuestion.question,
                                  index,
                                  Listindex,
                                  testQuestion.id
                                )
                              "
                              class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                            >
                              {{ urls[testQuestion.id] ? 'Ubah Foto' : 'Upload Foto' }}
                            </button>
                          </div>
                        </div>
                        <div 
                          id="uploaded-photo"
                          v-if="urls[testQuestion.id]"
                          class="my-1 mb-10"
                        >
                          <p class="font-semibold text-sm py-1">Foto: </p>
                          <div
                            class="flex justify-center"
                          >
                            <img
                              :src="urls[testQuestion.id]"
                              class="h-48"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex w-full mt-2 lg:mt-4 justify-center">
                <button
                  @click="submitPracticalTest()"
                  :class="`bg-blue-500 hover:bg-blue-700 text-white font-bold m-2 py-2 px-4 rounded ${loadingBtn ? 'opacity-50 cursor-wait' : ''}`"
                >
                  Simpan
                </button>
              </div>
            </div>
          </div>
        </template>
      </div>
    </main>
  </div>
</template>

<script>
import Loading from "@/components/widgets/Loading.vue";
import testApi from "@/api/test";

export default {
  data() {
    return {
      isUpload: false,
      preparedPhoto: {},
      modal: true,
      loading: false,
      participant: [],
      checkListSection: [],
      practicalListSection: "",
      full_name: "",
      photoUpload: {},
      file_names: {},
      section: '',
      practicalAnswers: {
        id: {}
      },
      checkLists: null,
      urls: {},
      answers: {
        id: {},
      },
      currentQuestionId: "",
      loadingBtn: false,
    };
  },

  components: {
    Loading,
  },

  async created() {
    this.loading = true;
    await this.getPracticalTest();

    this.getSection();
  },

  methods: {
    checkLabel(firstIndex, SecondIndex, type) {
      return firstIndex.toString() + SecondIndex.toString() + type;
    },
    checkBoldOrNot(index) {
      if (index === 1) {
        return "font-bold";
      }
    },
    onFileChange() {
      let file = this.$refs.file.files[0];

      this.preparedPhoto = Object.assign({}, {
        name: file.name,
        url: URL.createObjectURL(file),
        file: file
      })
    },
    popUpModal(data, index, Listindex, questionId) {
      if (data != false) {
        this.listQuestionForModal = data;
        this.currentIndex = index;
        this.Listindex = Listindex;
        this.currentQuestionId = questionId;
      }
      
      this.preparedPhoto = {};
      this.isUpload = !this.isUpload;
    },
    uploadPhoto() {
      if (!this.preparedPhoto.file) {
        this.$swal('Oops...', 'Tidak ada file foto yang diupload', 'error');
        return;
      }

      let idMd5 = this.$route.params.id;

      let formData = new FormData();
      
      formData.append("photo", this.preparedPhoto.file);
      formData.append("question_id", this.currentQuestionId);
      formData.append("participant_id", idMd5);

      testApi.uploadPhotoPracticalTest(formData)
        .then((response) => {
          this.photoUpload[this.currentQuestionId] = this.preparedPhoto.file;
          this.file_names[this.currentQuestionId] = this.preparedPhoto.name;
          this.urls[this.currentQuestionId] = response.data.data;
          this.$swal("", "Berhasil Submit Photo", 'success');

          this.isUpload = false;
        }).catch(error => {
          console.log(error.response);

          if (error.response.status == 400) {
            this.$swal('Oops...', error.response.data.message, 'error');
          } else {
            this.$swal('Oops...', 'Terdapat kesalahan dalam server', 'error');
          }
        });
    },
    toggleModal() {
      this.modal = true;
    },
    submitPreTest() {
      this.loadingBtn = true;

      let submittedAnswers = this.answers.id;

      let checkListSectionLength = 0;

      for (let i = 0; i < this.checkListSection.length; i++) {
        checkListSectionLength += this.checkListSection[i].checklists.length;
      }

      console.log(checkListSectionLength);

      if (Object.keys(submittedAnswers).length != checkListSectionLength) {
        this.loadingBtn = false;

        this.$swal('', 'Pastikan semua jawaban sudah terisi !!!', 'info');
      } else {
        let idMd5 = this.$route.params.id;
        let formData = new FormData();

        formData.append("checklist", JSON.stringify(submittedAnswers));

        testApi.postPrePracticalTestAnswer(idMd5, formData)
          .then((response) => {
            this.loadingBtn = false;

            this.$swal('', 'Data berhasil disimpan!', "success");
            this.section = "B";
          }).catch((error) => {
            this.loadingBtn = false;

            console.log(error);
            this.$swal("Oops...", "Terdapat kesalahan dalam server", "error");
          });
      }
    },
    submitPracticalTest() {
      this.loadingBtn = true;

      let submittedAnswers = this.practicalAnswers.id;
      let questionSectionLength = 0;

      for (let i = 0; i < this.practicalListSection.length; i++) {
        questionSectionLength += this.practicalListSection[i].sub_sections.length;
      }

      if (Object.keys(submittedAnswers).length != questionSectionLength) {
        this.loadingBtn = false;
        this.$swal('', 'Pastikan semua jawaban sudah terisi !!!', 'info');
        return;
      } 
      
      if (Object.keys(this.urls).length != questionSectionLength) {
        this.loadingBtn = false;
        this.$swal('', 'Pastikan semua foto sudah disubmit !!!', 'info');
        return;
      }

      let idMd5 = this.$route.params.id;
      let formData = new FormData();

      formData.append("practical_test_answers", JSON.stringify(submittedAnswers));

      testApi.postPracticalTestAnswer(idMd5, formData)
        .then((response) => {
          this.loadingBtn = false;
          this.$swal('', 'Data berhasil disimpan!', "success");
          this.$router.push({ name: 'FinalResult' });
        }).catch((error) => {
          this.loadingBtn = false;
          console.log(error);
          this.$swal("Oops...", "Terdapat kesalahan dalam server", "error");
        });
    },
    async getPracticalTest() {
      try {
        let idMd5 = this.$route.params.id;
        const response = await testApi.getPracticalTest(idMd5);
        this.participant = response.data.data.test;
        this.full_name = response.data.data.test.part_timer.full_name;
        this.checkListSection = response.data.data.checlist_sections;
        this.practicalListSection = response.data.data.practical_test_sections;

        this.loading = false;
      } catch (error) {
        console.log(error);
        this.$router.push({ name: "Login" });
        this.$swal("Oops...", "Data part timer tidak ditemukan", "error");
      }
    },
    getSection() {
      let participant = this.participant;

      console.log(this.participant);

      if (participant.status == 'passed'
        && participant.training_date == null
      ) {
        this.$router.push({ name: "Login" });
        this.$swal("Oops...", "Jadwal training belum ditentukan", "error");
      } else if (participant.pre_practical_test == 0 
        && participant.practical_test_score == null
        && participant.status == 'passed'
      ) {
        this.section = 'A';
      } else if (participant.pre_practical_test == 1 
        && participant.practical_test_score == null
        && participant.status == 'passed'
      ) {
        this.section = 'B';
      } else {
        this.$router.push({ name: "Login" });
        this.$swal("Oops...", "Data part timer tidak ditemukan", "error");
      }
    }
  },

};
</script>

<style lang="css">
hr {
  margin-top: 20px;
  margin-bottom: 20px;
}
.line-bold {
  border: none;
  height: 1px;
  /* Set the hr color */
  color: #333; /* old IE */
  background-color: #333; /* Modern Browsers */
}
ol {
  list-style: upper-roman !important;
}
</style>
