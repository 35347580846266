<template>
  <div class="flex flex-col min-h-screen">
    <Header />

    <main class="w-full max-w-3xl mx-auto p-2">
      <div class="shadow-lg bg-local bg-center text-md rounded-b-lg md:text-xl">
        <div class="sticky -top-1 justify-center w-full">
          <div
            class="flex align-middle items-center justify-center border-2 bg-yellow-500 p-1 w-full rounded-lg font-semibold"
          >
            <span class="text-white">waktu tersisa :</span>&nbsp;
            <span class="text-gray-800">{{ timeLeft }}</span>
          </div>
        </div>

        <div class="flex text-3xl m-8 mt-20 justify-center font-extrabold">
          {{ this.testTitle }}
        </div>

        <div class="text-left md:text-center text-base md:text-xl m-12">
          <span v-html="this.testContent"></span>
        </div>
        <div class="italic text-blue-400 font-bold">
          Waktu pengerjaan 10 menit
        </div>

        <div class="flex flex-col justify-center px-8 py-4 md:px-16">
          <div class="text-xs text-left font-medium md:text-lg">
            <div
              class="mt-8 md:mt-10"
              v-for="(question, questionIndex) in questions"
              :key="question.id"
            >
              <div class="flex w-full">
                {{ questionIndex + 1 }}. 
                <span class="ml-1" v-html="question.text"></span>
              </div>
              <div
                v-show="question.image !== ''"
                class="flex my-2 justify-center md:justify-start md:ml-16"
              >
                <img :src="`/img/price-tag/${question.image}`" />
              </div>

              <div
                class="flex w-full flex-col"
                v-for="(answer, index) in question.answers"
                :key="answer.id"
              >
                <label
                  class="inline-flex items-center mt-3 border-blue-200 border p-4 rounded-lg"
                >
                  <input
                    type="radio"
                    v-model="answers.id[questionIndex]"
                    :value="question.answers[index].id"
                    :name="question.id"
                    class="form-radio h-5 w-5 text-yellow-500"
                  />
                  <span class="ml-2 text-gray-700">{{ answer.text }}</span>
                </label>
              </div>
            </div>
          </div>

          <div class="mt-10 mb-4">
            <button
              :disabled="loadingBtn"
              @click="submitAnswers"
              :class="`bg-green-500 w-full font-semibold rounded-md border-0 py-2 px-1 text-white active:bg-green-600 hover:bg-green-600 ${
                loadingBtn ? 'opacity-50 cursor-wait' : ''
              }`"
            >
              Simpan
            </button>
          </div>
        </div>
      </div>
    </main>

    <Footer />
  </div>
</template>

<script>
import testApi from "@/api/test";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      typeTest: "",
      count: 0,
      time: 0,
      questions: [],
      testTitle: "",
      testContent: "",
      answers: {
        id: [],
      },
      answer_id: null,
      timer: null,
      submittedAnswer: [],
      loadingBtn: false,
    };
  },
  computed: {
    ...mapGetters(["token", "participant"]),

    timeLeft() {
      return `${this.minutes} menit ${this.seconds} detik`;
    },
    minutes() {
      return String(Math.floor(this.time / 60)).padStart(2, "0");
    },
    seconds() {
      return String(this.time % 60).padStart(2, "0");
    },
  },

  created() {
    if (this.participant.participant.current_step == "result" || this.participant.participant.current_step == "guidelines") {
      this.loading = false;
      this.logout();
      this.$router.push({ name: "Login" });
      this.$swal("Oops...", "Mohon tidak menggunakan tombol kembali pada browser atau device anda", "error");
    }
  },

  beforeDestroy() {
    this.time = 0;
  },

  mounted() {
    this.typeTest =
      this.$route.params.typeTest === "pre-test" ? "pre-test" : "post-test";

    this.getQuestions();
    this.timer = setInterval(this.decrementOrAlert, 1000);

    if (this.typeTest === "pre-test") {
      this.testTitle = " Tes Awal";
      this.testContent =
        "Sebelum Anda Mempelajari materi Pembekalan Part Timer ini,<br/> silakan jawab pertanyaan pertanyaan berikut ini";
    } else {
      this.testTitle = " Tes Akhir";
      this.testContent =
        "Demikian tadi materi pembekalan Anda sebagai Part Timer di Toko Indomaret.<br /> Jika Anda sudah paham dengan semua materi sebelumnya <br />,maka silahkan kerjakan Post Test berikut. Selamat mengerjakan dan semoga berhasil !";
    }

    this.initialTimeTest();
  },

  watch: {
    time(val) {
      if (val === 0) this.submitAnswers();
      return val;
    },
  },
  methods: {
    ...mapActions(['logout']),

    decrementOrAlert() {
      if (this.time > 0) {
        this.time--;
        localStorage.time--;
        return;
      }
      clearInterval(this.timer);
    },

    submitAnswers() {
      this.loadingBtn = true;
      if (this.time != 0) {
        if (this.answers.id.length != this.questions.length) {
          this.loadingBtn = false;

          return this.$swal(
            "info",
            "Pastikan semua jawaban sudah terisi !!!",
            "info"
          );
        }
      }

      let formData = new FormData();
      formData.append("answers", JSON.stringify(this.answers.id));

      testApi
        .postAnswers(this.typeTest, formData, this.token)
        .then(() => {
          if (this.time === 0) {
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              icon: "info",
              title: "Maaf, waktu tes Anda sudah habis",
              timerProgressBar: true,
            });
          }

          localStorage.removeItem("time");
          this.loadingBtn = false;
          this.redirectTo();
        })
        .catch((err) => {
          this.loadingBtn = false;
          console.log(err);
          this.$swal("Sesi habis", err.response.data, "error");
        });
    },
    getQuestions() {
      testApi
        .getParticipantQuestionTest(this.token)
        .then((res) => {
          const data = res.data.data.question;
          this.questions = data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    redirectTo() {
      if (this.typeTest === "pre-test")
        this.$router.push({ name: "Guidelines" });
      else this.$router.push({ name: "Result" });
    },

    initialTimeTest() {
      console.log(localStorage.time);
      if (localStorage.time == undefined || localStorage.time == "NaN" || localStorage.time < 0) {
        localStorage.time = process.env.VUE_APP_TIME_TEST;
        this.time = localStorage.time;
      } else this.time = localStorage.time;
    },
  },
};
</script>

<style>
@media screen {
  .w-media-full {
    width: 100%;
  }
}
</style>