<template>
  <div class="flex justify-center items-center min-h-screen">
    <main class="w-full max-w-3xl mx-auto p-2">
      <div class="shadow-lg bg-local bg-center text-md md:text-xl">
        <template v-if="loading">
            <Loading />
        </template>
        <template v-else>
            <div class="bg-blue-500 rounded rounded-md font-bold text-white">
                <h1 class="text-xl md:text-3xl font-bold py-8 px-2 md:p-10">Hasil penilaian praktek telah berhasil tersimpan, 
                    silakan hubungi HRD Cabang untuk proses selanjutnya.
                </h1>
            </div>
        </template>
      </div>
    </main>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import testApi from '@/api/test';
import Loading from '@/components/widgets/Loading.vue';

export default {
    data() {
        return {
            loading: false,
        }
    },
    components: {
        Loading
    },
    async created() {
        this.loading = true;

        await this.getFinalResult();
        this.checkParticipant();
    },
    methods: {
        ...mapActions(['setParticipant']),
        ...mapGetters(['participant']),

        async getFinalResult() {
            try {
                let idMd5 = this.$route.params.id;

                const response = await testApi.getFinalResult(idMd5);

                this.setParticipant(response.data.data);

                this.loading = false;
            } catch (error) {
                console.log(error);
                this.$router.push({ name: "Login" });
                this.$swal("Oops...", "Data part timer tidak ditemukan", "error");
            }
        },
        checkParticipant() {
            let data = this.participant();

            if (data.pre_practical_test != 1 &&
                data.practical_test_score == null &&
                data.practical_test_score == null) 
            {
                this.$router.push({ name: "Login" });
                this.$swal("Oops...", "Part timer belum melaksanakan tes praktek", "error");
            }
        }
    }
}
</script>